import React from "react"
import styled from "styled-components"

const FooterContainer = styled.footer`
  background: ${({ theme }) => theme.colors.beukelaarGreenDark};
  color: ${({ theme }) => theme.colors.white};
`

const InnerContainer = styled.div`
  padding: 2rem 1rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 2rem;
  }

  @media screen and (min-width: ${({ theme }) => theme.maxWidth}) {
    width: ${({ theme }) => theme.maxWidth};
    margin: 0 auto;
  }
`

const Footer: React.FC = ({}) => (
  <FooterContainer>
    <InnerContainer>
      <p>© {new Date().getFullYear()} De Beukelaar Groep</p>
    </InnerContainer>
  </FooterContainer>
)

export default Footer
