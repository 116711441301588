import React, { ReactNode } from "react"
import styled from "styled-components"
import BaseLink from "./base-link"

const Link = styled(BaseLink)`
  color: ${({ theme }) => theme.colors.beukelaarGreenLight};
  font-size: inherit;
`

interface SecondaryLinkProps {
  children: ReactNode
  to?: string
  href?: string
  className?: string
  isOnDark?: boolean
}

const SecondaryLink: React.FC<SecondaryLinkProps> = ({
  className,
  isOnDark = false,
  children,
  to,
  href,
}) => (
  <Link isOnDark={isOnDark} to={to} href={href} className={className}>
    {children}
  </Link>
)

export default SecondaryLink
