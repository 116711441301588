import { DefaultTheme } from "styled-components"

const BeukelaarTheme: DefaultTheme = {
  maxWidth: "1680px",
  transitions: {
    arrowOnHover: "transform 0.3s cubic-bezier(0.68, -0.6, 0.32, 1.6)",
  },
  colors: {
    beukelaarGreen: "#29837A",
    beukelaarGreenLight: "#40C1B4",
    beukelaarGreenDark: "#20645D",
    beukelaarYellow: "#FCCF47",
    beukelaarRed: "#F54F3F",
    sea: "#CDE5E5",
    sand: "#F4E8CE",
    offBlack: "#262626",
    grey: "#EFEEED",
    white: "#FFFFFF",
    sandLight: "#FAF7F2",
  },
  breakpoints: {
    tablet: "768px",
    laptop: "1024px",
    desktop: "1440px",
  },
}

export { BeukelaarTheme }
