import React from "react"

import styled from "styled-components"

import { NavigationLink } from "../links"

import FullLogoSVG from "../../svg/dbg-logo-full.svg"
import FullLogoDarkSVG from "../../svg/dbg-logo-full-dark.svg"

const HeaderContainer = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 2rem 0 4rem 0;
  min-height: 48px;

  > * + * {
    margin-top: 2rem;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    flex-direction: row;
    align-items: center;
    margin: 2rem 0 6rem 0;

    > * + * {
      margin-top: 0;
    }
  }
`

const LogoContainer = styled.div`
  width: 192px;
  height: 100%;
  display: flex;

  svg {
    width: 100%;
    height: 100%;
  }
`

interface HeaderProps {
  isOnDark?: boolean
}

const SimpleHeader: React.FC<HeaderProps> = ({ isOnDark = false }) => (
  <HeaderContainer>
    <LogoContainer>
      <NavigationLink ariaLabel="Homepage of De Beukelaar Groep" to="/">
        <LogoContainer>
          {isOnDark ? <FullLogoDarkSVG /> : <FullLogoSVG />}
        </LogoContainer>
      </NavigationLink>
    </LogoContainer>
  </HeaderContainer>
)

export default SimpleHeader
