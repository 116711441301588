import React, { ReactNode } from "react"
import styled from "styled-components"
import BaseLink from "./base-link"

import ArrowRight from "../../svg/arrow-right.svg"

const Link = styled(BaseLink)`
  text-transform: uppercase;

  svg {
    margin-right: 1rem;
    min-width: 1.5rem;
    min-height: 1.5rem;
    transition: ${({ theme }) => theme.transitions.arrowOnHover};

    fill: ${props =>
      props.isOnDark
        ? ({ theme }) => theme.colors.white
        : ({ theme }) => theme.colors.offBlack};
  }

  &:hover,
  &:focus {
    color: ${props =>
      props.isOnDark
        ? ({ theme }) => theme.colors.beukelaarGreenLight
        : ({ theme }) => theme.colors.beukelaarGreen};

    svg {
      fill: ${props =>
        props.isOnDark
          ? ({ theme }) => theme.colors.beukelaarGreenLight
          : ({ theme }) => theme.colors.beukelaarGreen};

      transform: translateX(4px);
    }
  }
`

interface PrimaryLinkProps {
  children: ReactNode
  to?: string
  href?: string
  className?: string
  isOnDark?: boolean
  download?: boolean
}

const PrimaryLink: React.FC<PrimaryLinkProps> = ({
  className,
  isOnDark = false,
  children,
  to,
  href,
  download,
}) => (
  <Link isOnDark={isOnDark} to={to} href={href} className={className} download>
    <ArrowRight />
    {children}
  </Link>
)

export default PrimaryLink
