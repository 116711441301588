import React from "react"

import { ToastContainer as ToastifyContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import styled from "styled-components"

const StyledToastifyContainer = styled(ToastifyContainer)`
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 1px;
  text-transform: uppercase;

  .Toastify__toast {
    background: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.offBlack};
    font: inherit;
    border-radius: 0;
    padding: 1rem 2rem;
    width: auto;
  }

  .Toastify__close-button {
    align-self: center;
  }

  .Toastify__toast--success {
    background: ${({ theme }) => theme.colors.beukelaarGreenLight};
    color: ${({ theme }) => theme.colors.white};
  }

  .Toastify__toast--error {
    background: ${({ theme }) => theme.colors.beukelaarRed};
    color: ${({ theme }) => theme.colors.white};
  }
`

const ToastContainer = () => (
  <StyledToastifyContainer
    position="top-right"
    hideProgressBar
    icon={false}
    autoClose={3000}
  />
)

export default ToastContainer
