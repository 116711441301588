import React from "react"
import styled, { createGlobalStyle, ThemeProvider } from "styled-components"
import reset from "styled-reset"

import { Header, SimpleHeader } from "../components/headers"
import Main from "../components/main"
import Footer from "../components/footer"
import ToastContainer from "../components/toast-container"

import { BeukelaarTheme } from "../themes/beukelaar-theme"

import "../styles/typography.css"

const GlobalStyle = createGlobalStyle`
  ${reset}

  html, body {
    padding: 0;
    margin: 0;
  }

  a {
    color: inherit;
    text-decoration: none;
    width: fit-content;
  }

  html {
    box-sizing: border-box;
    height: -webkit-fill-available;
    background-color: ${({ theme }) => theme.colors.sandLight};
    color: ${({ theme }) => theme.colors.offBlack};
  }

  body {
    font-family: ProximaNova, Proxima Nova, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.75px;
    min-height: -webkit-fill-available;
    
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  // Bug where the image is less then the parent's container height
  .gatsby-image-wrapper-constrained {
    display: block;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    body {
      font-size: 20px;
      line-height: 32px;
    }
  }
`

const LayoutContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`

const PaddingContainer = styled.div`
  flex: 1 0 auto; // Makes the footer sticky to the bottom
  padding: 0 1rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 0 2rem;
  }

  @media screen and (min-width: ${({ theme }) => theme.maxWidth}) {
    width: ${({ theme }) => theme.maxWidth};
    margin: 0 auto;
  }
`

const DefaultLayout: React.FC = ({ children }) => (
  <ThemeProvider theme={BeukelaarTheme}>
    <GlobalStyle />
    <LayoutContainer>
      <PaddingContainer>
        <Header />
        <Main>{children}</Main>
      </PaddingContainer>
      <Footer />
      <ToastContainer />
    </LayoutContainer>
  </ThemeProvider>
)

const NotFoundStyles = createGlobalStyle`
  html, body {
    background-image: url(/404.svg);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
  }
`

const NotFoundLayout: React.FC = ({ children }) => (
  <ThemeProvider theme={BeukelaarTheme}>
    <GlobalStyle />
    <NotFoundStyles />
    <LayoutContainer>
      <PaddingContainer>
        <SimpleHeader isOnDark />
        <Main>{children}</Main>
      </PaddingContainer>
      <ToastContainer />
    </LayoutContainer>
  </ThemeProvider>
)

interface LayoutProps {
  pageContext: any
}

const Layout: React.FC<LayoutProps> = ({ children, pageContext }) => {
  const isNotFoundPage = pageContext.layout === "404"

  return isNotFoundPage ? (
    <NotFoundLayout>{children}</NotFoundLayout>
  ) : (
    <DefaultLayout>{children}</DefaultLayout>
  )
}

export default Layout
