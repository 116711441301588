import React, { ReactNode } from "react"
import styled from "styled-components"
import BaseLink from "./base-link"

interface StyledLinkProps {
  $isOnDark: boolean
}

const Link = styled(BaseLink)<StyledLinkProps>`
  text-transform: uppercase;
  font-size: 12px;

  &:hover {
    color: ${props =>
      props.$isOnDark
        ? ({ theme }) => theme.colors.beukelaarGreenLight
        : ({ theme }) => theme.colors.beukelaarGreen};
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: 16px;
  }
`

interface NavigationLinkProps {
  children: ReactNode
  ariaLabel?: string
  to?: string
  href?: string
  className?: string
  isOnDark?: boolean
}

const NavigationLink: React.FC<NavigationLinkProps> = ({
  className,
  isOnDark = false,
  ariaLabel,
  children,
  to,
  href,
}) => (
  <Link
    $isOnDark={isOnDark}
    ariaLabel={ariaLabel}
    to={to}
    href={href}
    className={className}
  >
    {children}
  </Link>
)

export default NavigationLink
